<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay max-width="100%">
    <v-card>
      <v-app-bar dark fixed>
        <v-toolbar-title>Appointment details</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon plain @click="onClose()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container class="mt-14">
        <v-card>
          <v-simple-table class="appointment-details-table">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Service User Name</td>
                  <td>{{ appointment.customer }}</td>
                </tr>
                <tr v-if="appointment.isTwoCarer">
                  <td>Second Carer</td>
                  <td>
                    <span v-if="appointment.secondCarer">{{
                      appointment.secondCarer
                    }}</span>
                    <span v-else>*Not Set*</span>
                  </td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>{{ appointment.date }}</td>
                </tr>
                <tr>
                  <td>From Time</td>
                  <td>{{ appointment.start_time }}</td>
                </tr>
                <tr>
                  <td>End Time</td>
                  <td>{{ appointment.end_time }}</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>{{ appointment.duration }}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>
                    {{ appointment.task }}
                    <span v-if="appointment.isTwoCarer">(Two Carer Task)</span>
                  </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>
                    {{ appointment.address.add1 }},
                    {{ appointment.address.add2 }},
                    {{ appointment.address.pcode }}
                  </td>
                </tr>
                <tr v-if="appointment.isCritical === 1">
                  <td  class="red--text">Appointment Urgency</td>
                  <td class="red--text">
                    This is a time critical task
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-btn
          v-if="!isCheckedOut"
          class="appointment-action-button"
          :color="button_color"
          :disabled="button_disabled"
          @click="onClickAction"
        >
          {{ button_label }}
        </v-btn>

        <!-- <p
          v-show="this.appointment.status === 'NOT_STARTED'"
          class="popuplink text-right primary--text text-decoration-underline font-italic"
          @click="onClickFrustrated"
        >Frustrated</p> -->

        <v-card>
          <v-simple-table class="appointment-details-table mt-4">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>{{ formatted_status }}</td>
                </tr>
                <tr>
                  <td>Checked in</td>
                  <td>
                    {{
                      appointment.status === "NOT_STARTED"
                        ? ""
                        : appointment.checkin_time
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Checked out</td>
                  <td>
                    {{
                      appointment.status === "NOT_STARTED" ||
                      appointment.status === "CHECKED_IN"
                        ? ""
                        : appointment.checkout_time
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-container>

      <qr-dialog
        :dialog="qrDialog"
        @qrCodeRead="onQRCodeRead"
        @qrCodeClose="onQRCodeClose"
      >
      </qr-dialog>
      <v-dialog v-model="frustratedDialog">
        <v-card>
          <v-card-title>Report frustrated call</v-card-title>
          <v-card-text>
            <v-form ref="frustratedReport">
              <v-row>
                <v-col class="text-left" cols="12">
                  <span
                    >Please state you reason for reporting this call
                    frustrated.</span
                  >
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-textarea
                    counter
                    maxlength="200"
                    label="Reason"
                    v-model="frustratedMessage.reason"
                    :rules="[
                      (v) =>
                        (v && v.length >= 10) ||
                        'Plase leave a meaningful comment',
                    ]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="darken-1" text @click="onCloseFrustrated()"
              >Close</v-btn
            >
            <v-btn color="green darken-1" @click="onConfirmFrustrated()"
              >Send</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <error-snackbar
      :snackbar="response.status"
      :text="response.text"
      :color="response.color"
      @close="onCloseSnackbar"
    >
    </error-snackbar>
  </v-dialog>
</template>

<script>
import QrDialog from "./QrDialog.vue";
import SelectFunctions from "../select/SelectFunctions";
import ErrorSnackbar from "./ErrorSnackbar.vue";

export default {
  components: { QrDialog, ErrorSnackbar },
  name: "AppointmentDetailsPopup",
  data() {
    return {
      frustratedDialog: false,
      frustratedMessage: {
        reason: "",
      },
      qrDialog: false,

      response: {
        status: false,
        text: "",
        color: "",
      },
    };
  },

  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    appointment: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    formatted_status() {
      return this.appointment.status === "NOT_STARTED"
        ? "Yet to check in"
        : this.appointment.status === "CHECKED_IN"
        ? "Checked in"
        : this.appointment.status === "CHECKED_OUT"
        ? "Checked out"
        : this.appointment.status === "FRUSTRATED"
        ? "Frustrated"
        : "Unknown status";
    },
    aAppointmentInfos() {
      return Object.entries(this.appointment);
    },

    button_disabled() {
      return this.appointment.active && this.appointment.status !== "FRUSTRATED"
        ? false
        : true;
    },

    button_label() {
      switch (this.appointment.status) {
        case "NOT_STARTED":
          return "Check In";
          break;
        case "CHECKED_IN":
          return "Check Out";
          break;
        case "CHECKED_OUT":
          return "";
          break;
        default:
          return "";
      }
    },


   

    button_color() {
      switch (this.appointment.status) {
        case "NOT_STARTED":
          return this.appointment.active
            ? "var(--action-button-checkin)"
            : "var(--action-button-disabled)";
          break;
        case "CHECKED_IN":
          return this.appointment.active
            ? "var(--action-button-checkout)"
            : "var(--action-button-disabled)";
          break;
        case "CHECKED_OUT":
          return "var(--action-button-expired)";
          break;
        default:
          return "";
      }
    },

    isCheckedOut() {
      return (
        this.appointment.status === "CHECKED_OUT" ||
        this.appointment.status === "FRUSTRATED"
      );
    },
  },

  methods: {
    /**
     * Close dialog //appointmentDetails//
     */
    onClose() {
      this.$emit("close");
    },

    /**
     * If it makes sense, let the user check in/out
     */
    onClickAction() {
      if (
        this.appointment.active &&
        this.appointment.status !== "CHECKED_OUT"
      ) {
        this.qrDialog = true;
      }
    },

    onClickFrustrated() {
      this.frustratedDialog = true;
    },
    onCloseFrustrated() {
      this.$refs.frustratedReport.reset();
      this.frustratedDialog = false;
    },
    async onConfirmFrustrated() {
      if (this.$refs.frustratedReport.validate()) {
        await this.reportFrustrated(this.frustratedMessage);
        this.frustratedDialog = false;
        this.$refs.frustratedReport.reset();
      }
    },

    async reportFrustrated(oMessage) {
      var response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + "schedule/" + "frustrated",
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            sh_syskey: this.appointment.id,
            ti_reason: oMessage.reason,
          }),
        }
      );

      switch (response.status) {
        case 200:
          this.onSuccessfulQRRead({ status: "FRUSTRATED" });
          this.onOpenSnackbar("Saved successfully.", "success");
          break;
        default:
          this.onOpenSnackbar(
            "Error while saving. Please try again later.",
            "error"
          );
      }
    },

    /**
     * Close dialog //qrDialog
     */
    onQRCodeClose() {
      this.qrDialog = false;
    },

    /**
     * When a QR code is read, close the dialog //qrDialog
     * send the read string for processng
     */
    onQRCodeRead(sCode) {
      this.qrDialog = false;
      this.qrCheck(sCode);
    },

    /**
     * Send the read QRcode to the BE for processing
     * include the session data too
     */
    async qrCheck(sCode) {
      var oParams = {
        sh_syskey: this.appointment.id,
        sh_csyskey: sCode,
      };

      var response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + "schedule/" + "checkInOut",
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(oParams),
        }
      );

      switch (response.status) {
        case 200:
          this.onOpenSnackbar("Success", "success");
          var oResponseJSON = await response.json();
          this.onSuccessfulQRRead(oResponseJSON);
          break;
        case 400:
          this.onOpenSnackbar("Invalid code", "error");
          break;
        default:
          this.onOpenSnackbar("Unknown error", "error");
      }
    },

    /**
     * If the user successfully changed the status of the appointment, notify the parent
     * manual sync
     */
    onSuccessfulQRRead(oData) {
      this.$emit("statusChange", oData.status, this.appointment.id);
    },

    /**
     * Open message toast skeleton
     */
    onOpenSnackbar(sText, sColor) {
      this.response.status = true;
      this.response.text = sText;
      this.response.color = sColor;
    },

    /**
     * Close message toast
     */
    onCloseSnackbar: function() {
      this.response.status = false;
    },
  },
};
</script>
<style scoped></style>
