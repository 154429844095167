<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay max-width="100%">
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Scan QR Code</v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-btn icon @click="onCloseQRDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
        <v-card-text class="pa-0">
        <v-container fill-height fluid class="pa-0" v-if="!error">
          <qr-code-stream
            v-if="dialog"
            @decode="onDecode"
            @init="onInit"
            :track="paintOutline"
            style="height: calc(100% - 56px); width: 100%"
          >
          </qr-code-stream>
        </v-container>
        <v-container v-else>
          <v-alert color="error" class="white--text">
            {{error}}
          </v-alert>
          <v-card color="info">
            <v-card-text v-if="iOS" class="white--text">
              <span class="title">Permissions Help for iOS</span><br/>
              Safari will normally ask for permission to use the camera automatically when launching the application.<br/>
              You may just need to refresh the page, and try to scan the QR code again (ensuring you allow the camera permission).<br/>
              If this does not help, please ensure camera permissions are enabled for Safari as follows:
              <ol>
                <li>Open the Settings App.</li>
                <li>Tap on "Safari", and then "Camera".</li>
                <li>Scroll down to "Camera &amp; Microphone"</li>
                <li>Confirm that either "Ask" or "Allow" is checked.</li>
                <li>Refresh this page, and try again.</li>
              </ol>
            </v-card-text>
            <v-card-text v-else class="white--text">
              <span class="title">Permissions Help for Android Chrome</span>
              <ol>
                <li>On your Android device, open the Chrome app.</li>
                <li>On the right of the address bar, tap the More button (triple dots), then click Settings.</li>
                <li>Tap "Site Settings".</li>
                <li>Tap "Camera".</li>
                <li>Ensure the camera toggle is enabled and set to "Ask First", *not* "Blocked".</li>
                <li>Find and tap {{domain}} in the list of sites (ensure you expand the "Blocked" category).</li>
                <li>Set the permission to "Allow". *Not* "Block".</li>
                <li>Refresh this page, and try again.</li>
              </ol>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card-text>

      
    </v-card>
  </v-dialog>
</template>

<script>
import {QrcodeStream} from "vue-qrcode-reader";

export default {
  name: "QrDialog",
  components: {
    "qr-code-stream": QrcodeStream
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
  },
  methods: {

    /**
     * Notify the parent that a QRcode is read
     */
    onDecode(sCode) {
      this.$emit("qrCodeRead", sCode);
    },

    /**
     * Notify the parent to close this dialog
     */
    onCloseQRDialog() {
      this.$emit("qrCodeClose");
    },

    async onInit(promise) {
      try {
        let capabilities = await promise;
        console.log("QR Code reader initialized, capabilities:", capabilities);

        this.error = false;
      }
      catch(error) {
        console.log("QR Code reader initialization failed");
        console.log("Exception was: ", error);

        switch(error.name)
        {
          case "NotAllowedError":
            this.error = "Permission was denied to access the camera. Please check your browser settings and enable camera access.";
            break;
          case "NotFoundError":
            this.error = "No suitable camera was found. Please ensure you are using a device with a camera.";
            break;
          case "NotSupportedError":
            this.error = "Connected over an insecure connection. Please ensure you are connected to this page over HTTPS.";
            break;
          case "NotReadableError":
            this.error = "Could not read data from the camera. Please ensure the camera is not already in use by any other applications.";
            break;
          case "OverconstrainedError":
            this.error = "The device's camera does not meet the requirements for this application.";
            break;
          case "SteamApiNotSupportedError":
            this.error = "The browser does not seem to support a required API. Please ensure your device, and Web Browser are up to date.";
            break;
          default:
            this.error = "An unknown error occurred. Exception name was: " + error.name;
            break;
        }
        console.log(this.error);
      }
    },

    
    paintOutline (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
  },

  data() {
    return {
      error: false,
      step: 1,
      domain: location.origin,
      iOS: [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document),
    }
  }
};
</script>
